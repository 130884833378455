import { useTradingDocument } from "api/trading-documents/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { AmountSection } from "pages/tradingDocuments/shared/components/rightPanel/amountSection/AmountSection";
import { CorrectionSection } from "pages/tradingDocuments/shared/components/rightPanel/correctionSection/CorrectionSection";
import { CorrespondingDocumentSection } from "pages/tradingDocuments/shared/components/rightPanel/correspondingDocumentsSection/CorrespondingDocumentSection";
import { GeneralInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/generalInfoSection/GeneralInfoSection";
import { NotificationSection } from "pages/tradingDocuments/shared/components/rightPanel/notificationSection/NotificationSection";
import { PaymentSection } from "pages/tradingDocuments/shared/components/rightPanel/paymentSection/PaymentSection";
import { TitleSection } from "pages/tradingDocuments/shared/components/rightPanel/titleSection/TitleSection";
import { PanelHeader } from "./components/PanelHeader";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { useRef } from "react";
import { RemindersSection } from "../../shared/components/rightPanel/remindersSection/RemindersSection";
import { CurrencyConvertSection } from "./components/currencyConvertSection/CurrencyConvertSection";
import { ViesSection } from "pages/tradingDocuments/shared/components/rightPanel/generalInfoSection/ViesSection";
import { CorrespondingReceiptSection } from "./components/correspondingReceiptSection/CorrespondingReceiptSection";
import { AssignAccountancyAccount } from "pages/tradingDocuments/shared/components/rightPanel/assignAccountancyAccount/AssignAccountancyAccount";
import { BuyerInfo } from "pages/tradingDocuments/shared/components/rightPanel/buyerInfoSection/BuyerInfo";
import { RecipientInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/recipientInfoSection/RecipientInfoSection";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { ItemsSection } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/salesInvoiceItems/ItemsSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: tradingDocument, error, isLoading } = useTradingDocument(
    { id: panelId },
    { enabled: Boolean(panelId) },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!tradingDocument) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={tradingDocument.id}>
        <PanelHeader />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody myRef={clearCommentsRef} hasCommentsModule>
            <TitleSection tradingDocument={tradingDocument} />
            {tradingDocument.correspondingReceipt && (
              <CorrespondingReceiptSection tradingDocument={tradingDocument} />
            )}
            <GeneralInfoSection tradingDocument={tradingDocument} />
            <AmountSection tradingDocument={tradingDocument} />
            <ViesSection tradingDocument={tradingDocument} />
            {Boolean(tradingDocument.items.length) && (
              <ItemsSection tradingDocument={tradingDocument} />
            )}
            {tradingDocument.currency !== "PLN" && (
              <CurrencyConvertSection tradingDocument={tradingDocument} />
            )}
            <PaymentSection tradingDocument={tradingDocument} />
            <NotificationSection tradingDocument={tradingDocument} />
            {Boolean(tradingDocument.corrections.length) && (
              <CorrectionSection tradingDocument={tradingDocument} />
            )}
            {Boolean(tradingDocument.correspondingDocuments.length) && (
              <CorrespondingDocumentSection tradingDocument={tradingDocument} />
            )}
            <RemindersSection tradingDocument={tradingDocument} />
            <AssignAccountancyAccount tradingDocument={tradingDocument} />
            <BuyerInfo tradingDocument={tradingDocument} />
            <RecipientInfoSection tradingDocument={tradingDocument} />
          </RightPanelBody>
          <CommentsGenericSection
            outsideRef={clearCommentsRef}
            paramName="tradingDocument"
            endpointUrl="/finances/comments/items"
            commentedObject={tradingDocument}
          />
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
