import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { OrderPackageExchangeListItem } from "api/order-packages-exchange/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { store } from "store/storeConfig";
import { OrderExchangeStatus } from "api/order-packages-exchange/enums";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { labelsFactory } from "api/labels/calls";
import { queryString } from "utilities";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";

export const usePackageExchangeColumns = () => {
  return useCreateTableColumns<OrderPackageExchangeListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 220,
        typographyProps: {
          fontWeight: "700",
          fontSize: "16",
        },
      }),
      columnHelper.date(row => row.createdAt, {
        header: "utworzono",
        size: 130,
        format: "dd.MM.yyyy, HH:mm",
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.createdBy, {
        header: "utworzono przez",
        size: 100,
        cell: info => {
          const createdBy = info.getValue();
          const user = store.getState().partials.users.find(user => user.uuid === createdBy);
          if (!user) {
            return <EmptyValue fontSize="12" fontWeight="600" />;
          }
          return (
            <div className="d-flex align-items-center gap-1">
              <Avatar user={user} size="sm" />
              <Typography fontSize="12" fontWeight="600" noWrap>
                {user.firstName} {user.lastName}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "status",
        size: 120,
        cell: info => {
          const status = info.getValue();

          return getStatusTag(status);
        },
      }),
      columnHelper.accessor(row => row.confirmedBy, {
        header: "skanowanie paczek",
        size: 130,
        cell: info => {
          const confirmedBy = info.getValue();

          if (!confirmedBy.length) {
            return <EmptyValue fontSize="16" />;
          }
          return (
            <div className="d-flex align-items-center gap-1">
              <div className="avatarBox">
                {confirmedBy.slice(0, 7).map(user => (
                  <Avatar className="mr-n1" key={user.id} user={user} size="xs" />
                ))}
              </div>
              {confirmedBy.length > 7 && (
                <MoreDataCounter
                  counter={confirmedBy.length - 1}
                  style={{ width: "max-content" }}
                />
              )}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.locations.join(", "), {
        header: "lokalizacja",
        size: 150,
        typographyProps: {
          fontWeight: "600",
          fontSize: "14",
        },
      }),
      columnHelper.date(row => row.finishedAt, {
        header: "zakończono",
        size: 130,
        format: "dd.MM.yyyy, HH:mm",
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "etykiety",
        size: 50,
        cell: info => {
          const row = info.getValue();
          return (
            <FileDownloadHandler
              mode="preview"
              factoryFn={() =>
                labelsFactory.labels(
                  queryString.stringify({ orders: [row.baseOrderId], addExchangedCodes: true }),
                  "Wymiana-paczek-",
                )
              }
              type="pdf"
            >
              {({ download, isLoading }) => (
                <div className="d-flex flex-1 align-items-center justify-content-center">
                  <IconButton
                    icon={MdiQrCode}
                    isLoading={isLoading}
                    variant="transparent"
                    onClick={event => {
                      event.stopPropagation();
                      download();
                    }}
                  />
                </div>
              )}
            </FileDownloadHandler>
          );
        },
      }),
    ];
  });
};

function getStatusTag(status: OrderPackageExchangeListItem["status"]) {
  switch (status) {
    case OrderExchangeStatus.FINISHED:
      return <Tag label="Zrealizowane" variant="success" />;

    case OrderExchangeStatus.IN_PROGRESS:
      return <Tag label="W trakcie" variant="warning" />;

    case OrderExchangeStatus.NOT_STARTED:
      return <Tag label="Niezrealizowane" variant="quaternary" type="outlined" />;

    default:
      const exhaustiveCheck: never = status;
      console.error(`Unhandled status: ${exhaustiveCheck}`);
      return <EmptyValue />;
  }
}
