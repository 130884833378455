import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { orderPackageExchangeApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";

const useOrderPackageExchange = createApiQuery(orderPackageExchangeApi.getOrderPackagesExchange);
const useSourceOrders = createApiQuery(orderPackageExchangeApi.getSourceOrders);

const useProductExchanges = createPaginatedApiQuery(orderPackageExchangeApi.getProductExchanges);
const useOrderPackageExchangeList = createPaginatedApiQuery(
  orderPackageExchangeApi.getOrderPackagesExchangeList,
);

const usePostLinkPackages = () =>
  useMutation(orderPackageExchangeApi.postLinkPackages, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const usePatchOrderExchange = () =>
  useMutation(orderPackageExchangeApi.patchOrderPackagesExchanges, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const usePatchPackageExchange = () =>
  useMutation(orderPackageExchangeApi.patchPackageExchange, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const usePostOrderPackagesExchange = () =>
  useMutation(orderPackageExchangeApi.postOrderPackagesExchange, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

export const orderPackageExchangeActions = {
  usePostOrderPackagesExchange,
  useOrderPackageExchange,
  useProductExchanges,
  useSourceOrders,
  usePatchOrderExchange,
  usePostLinkPackages,
  usePatchPackageExchange,
  useOrderPackageExchangeList,
};
