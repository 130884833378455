import { createPaginatedKey } from "api/keys";
import { OrderPackageExchange } from "./models";

export const orderPackageExchangeKeys = {
  list: createPaginatedKey("orderPackageExchanges"),
  details: (id: OrderPackageExchange["id"]) => ["orderPackageExchange", id],
  sourceOrders: (id: OrderPackageExchange["id"]) => ["sourceOrders", id],
  productExchanges: createPaginatedKey("productExchanges"),
  // lineItem: (returnLineItemId: UUID) => ["returnLineItem", returnLineItemId],
  // workspace: (userId: string) => ["returnWorkspace", userId],
};
