import { TradingDocument } from "api/trading-documents/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { columnFactory } from "../shared/columnsFactory/columnFactory";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "../shared/utils/isDateOlderThan24Hours";
import { cx } from "utilities";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import styles from "../tradingDocumentsList/TradingDocumentsList.module.css";

export const useProformaColumns = () => {
  return useCreateTableColumns<TradingDocument>(({ columnHelper }) => {
    const columns = columnFactory(columnHelper);
    return [
      columns.invoiceConfirmation(),
      columnHelper.accessor(row => row, {
        header: "nabywca",
        size: 200,
        cell: info => {
          const tradingDocument = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1 justify-content-between w-100">
              <Typography className={styles.invoiceSpacing} fontSize="14" fontWeight="700" noWrap>
                {tradingDocument.recipientCompanyName ||
                  `${tradingDocument.recipientFirstName} ${tradingDocument.recipientLastName}`}
              </Typography>
              {!isDateOlderThan48Hours(tradingDocument.createdAt) && (
                <div
                  className={cx("d-flex align-items-center", {
                    [styles.highOpacity]: isDateOlderThan24Hours(tradingDocument.createdAt),
                  })}
                >
                  <Tag label="Nowa" type="outlined" variant="success" />
                </div>
              )}
            </div>
          );
        },
      }),
      columns.signature(140),
      columns.amount(),
      columns.countryCode(),
      columns.invoiceIssueDate({ hasHighlight: false }),
      columns.paymentDeadline(),
      columns.invoiceOrders(),
      columns.paymentStatus(110),
      columns.paymentDeadlineDelta(100),
      columns.notificationStatus(),
      columns.assignedTo(),
      columnHelper.text(
        row => row.sellerCompanyName ?? `${row.sellerFirstName} ${row.sellerLastName}`,
        {
          header: "sprzedawca",
          size: 250,
          typographyProps: {
            className: styles.invoiceSpacing,
            color: "neutralBlack48",
            fontSize: "12",
            fontWeight: "500",
          },
        },
      ),
    ];
  });
};

// "20px minmax(130px, 2fr) 160px minmax(100px, 1fr) 50px 90px 90px 120px 70px 90px 70px 70px minmax(100px, 1fr)"
