import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useToggle } from "hooks";
import { AddPackageExchangeModal } from "./addPackageExchangeModal/AddPackageExchangeModal";
import { Table } from "components/miloDesignSystem/molecules/table";
import { usePackageExchangeColumns } from "./usePackageExchangeColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { orderPackageExchangeActions } from "api/order-packages-exchange/actions";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { OrderPackageExchangeListItem } from "api/order-packages-exchange/models";
import { MdiBoxEdit } from "components/miloDesignSystem/atoms/icons/MdiBoxEdit";
import { RightPanel } from "./rightPanel/RightPanel";

export const PackageExchange = () => {
  const addPackageExchangeModal = useToggle();

  const {
    data,
    error,
    isPreviousData,
    isLoading,
    pagination,
  } = orderPackageExchangeActions.useOrderPackageExchangeList("");
  const columns = usePackageExchangeColumns();
  const tableProps = useTableFeatureConnector({
    rows: data,
    withPagination: { pagination, defaultPaginationVisibility: true },
    withDrawer: "exchangeOrder",
  });

  return (
    <PageWrapper>
      <PageHeader
        createButton={{
          alt: "Wymień paczki",
          img: MdiBoxEdit,
          label: "Wymień paczki",
          onClick: addPackageExchangeModal.open,
        }}
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        viewLabel="WMS_PACKAGE_EXCHANGE"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<OrderPackageExchangeListItem>
          columns={columns}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
      </div>
      {addPackageExchangeModal.isOpen && (
        <AddPackageExchangeModal close={addPackageExchangeModal.close} />
      )}
    </PageWrapper>
  );
};
