import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { TradingDocument } from "api/trading-documents/models";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";

interface Props {
  tradingDocument: TradingDocument;
}

export const DownloadTradingDocumentPdf = ({ tradingDocument }: Props) => {
  const getTooltipSuffix = () => {
    switch (tradingDocument.invoiceType) {
      case "ADVANCE":
        return "zaliczki";
      case "CORRECTION":
        return "korekty";
      case "FINAL":
        return "faktury końcowej";
      case "PROFORMA":
        return "proformy";
      case "PURCHASE":
        return "faktury zakupowej";
      case "SALES":
        return "faktury sprzedażowej";
      default:
        const exhaustiveCheck: never = tradingDocument.invoiceType;
        console.error(`Unhandled invoice type: ${exhaustiveCheck}`);
        return "dokumentu handlowego";
    }
  };

  return (
    <FileDownloadHandler
      factoryFn={() => tradingDocumentFileFactory.tradingDocumentPdf(tradingDocument)}
      type="pdf"
    >
      {({ download, isLoading }) => (
        <Tooltip
          title={`Pobierz PDF z danymi ${
            tradingDocument.type === "RECEIPT" ? "paragonu" : getTooltipSuffix()
          }`}
        >
          <IconButton
            icon={<MdiDownloadPdf size="18" />}
            isLoading={isLoading}
            onClick={download}
            variant="transparent"
          />
        </Tooltip>
      )}
    </FileDownloadHandler>
  );
};
