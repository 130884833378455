import { ColumnDef } from "@tanstack/table-core";
import { TradingDocument } from "api/trading-documents/models";
import {
  ColumnHelperArgs,
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { ConfirmCheckbox, ReplyModal } from "../components/confirmCheckbox/ConfirmCheckbox";
import styles from "../../tradingDocumentsList/TradingDocumentsList.module.css";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay/AmountDisplay";
import { CURRENCY_TYPE } from "CONSTANTS";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { dateExceedsToday } from "../utils/dateExceedsToday";
import { dateUtils, pluralize } from "utilities";
import { Avatar } from "components/miloDesignSystem/atoms/avatar/Avatar";
import { MoreDataCounter } from "components/common/moreDataCounter/MoreDataCounter";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { PaymentDeadlineDeltaLabel } from "../components/PaymentDeadlineDeltaLabel";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";

export const columnFactory = (columnHelper: ColumnHelperArgs<TradingDocument>) => {
  return {
    invoiceConfirmation: (replyModal?: ReplyModal) =>
      columnHelper.accessor(row => row, {
        id: "invoiceConfirmation",
        header: " ",
        size: 30,
        cell: info => {
          const tradingDocument = info.getValue();
          return <ConfirmCheckbox tradingDocument={tradingDocument} replyModal={replyModal} />;
        },
      }) as ColumnDef<TradingDocument>,
    signature: (size: number) =>
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size,
        typographyProps: {
          className: styles.invoiceSpacing,
          fontWeight: "500",
        },
      }) as ColumnDef<TradingDocument>,
    amount: () =>
      columnHelper.accessor(row => row, {
        header: "kwota",
        size: 105,
        cell: info => {
          const tradingDocument = info.getValue();
          if (tradingDocument.amountSummary.totalWithTax)
            return (
              <AmountDisplay
                amount={tradingDocument.amountSummary.totalWithTax.toString()}
                currency={tradingDocument.currency as CURRENCY_TYPE}
                decimalTypographyProps={{
                  className: styles.invoiceSpacing,
                  fontSize: "10",
                  fontWeight: "700",
                }}
                integerTypographyProps={{
                  className: styles.invoiceSpacing,
                  fontSize: "12",
                  fontWeight: "700",
                }}
              />
            );
          return (
            <Typography fontSize="12" fontWeight="600">
              brak
            </Typography>
          );
        },
      }) as ColumnDef<TradingDocument>,
    invoicePaymentDate: () =>
      columnHelper.accessor(row => row.invoicePaymentDate, {
        header: "data sprzedaży",
        size: 90,
        cell: info => {
          const paymentDate = info.getValue();
          if (!paymentDate) return <EmptyValue fontSize="12" fontWeight="500" />;
          return (
            <Typography className={styles.invoiceSpacing} fontSize="12" fontWeight="500">
              {dateUtils.formatDateToDisplay(paymentDate)}
            </Typography>
          );
        },
      }) as ColumnDef<TradingDocument>,
    invoiceIssueDate: ({ hasHighlight }: { hasHighlight: boolean }) =>
      columnHelper.accessor(row => row.invoiceIssueDate, {
        header: "wystawiono",
        size: 80,
        cell: info => {
          const invoiceIssueDate = info.getValue();
          if (!invoiceIssueDate) return <EmptyValue fontSize="12" fontWeight="500" />;
          return (
            <Typography
              className={styles.invoiceSpacing}
              color={
                dateExceedsToday(invoiceIssueDate) && hasHighlight ? "danger600" : "neutralBlack88"
              }
              fontSize="12"
              fontWeight="500"
            >
              {dateUtils.formatDateToDisplay(invoiceIssueDate)}
            </Typography>
          );
        },
      }) as ColumnDef<TradingDocument>,
    paymentDeadline: () =>
      columnHelper.accessor(row => row, {
        header: "termin",
        size: 120,
        cell: info => {
          const tradingDocument = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              <Typography
                className={styles.invoiceSpacing}
                color={
                  tradingDocument.paymentDeadline &&
                  dateExceedsToday(tradingDocument.paymentDeadline)
                    ? "danger600"
                    : "neutralBlack88"
                }
                fontSize="12"
                fontWeight="500"
              >
                {tradingDocument.paymentDeadline
                  ? dateUtils.formatDateToDisplay(tradingDocument.paymentDeadline)
                  : EMPTY_VALUE}
              </Typography>
              {tradingDocument.basePaymentDeadlineDelta && (
                <Typography className={styles.invoiceSpacing} fontSize="12" fontWeight="500">
                  ({tradingDocument.basePaymentDeadlineDelta}{" "}
                  {pluralize.pl(tradingDocument.basePaymentDeadlineDelta, {
                    singular: "dzień",
                    plural: "dni",
                    other: "dni",
                  })}
                  )
                </Typography>
              )}
            </div>
          );
        },
      }) as ColumnDef<TradingDocument>,
    assignedTo: () =>
      columnHelper.accessor(row => row.assignedTo, {
        header: "przypisano",
        size: 80,
        cell: info => {
          const assignedTo = info.getValue();
          if (!assignedTo) return <EmptyValue fontSize="12" fontWeight="700" />;
          return (
            <div className="d-flex align-items-center gap-1 w-100">
              <Avatar user={assignedTo} size="xs" />
              <Typography className={styles.invoiceSpacing} fontSize="12" fontWeight="700" noWrap>
                {assignedTo.firstName} {assignedTo.lastName}
              </Typography>
            </div>
          );
        },
      }) as ColumnDef<TradingDocument>,
    countryCode: () =>
      columnHelper.country(row => row, {
        header: "kraj",
        size: 50,
        countryFlagProps: {
          countryCode: row => row.original.countryCode,
        },
      }) as ColumnDef<TradingDocument>,
    invoiceOrders: () =>
      columnHelper.accessor(row => row.orders, {
        header: "zamówienia",
        size: 120,
        cell: info => {
          const orders = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              {orders.slice(0, 1).map(order => (
                <Typography
                  className={styles.invoiceSpacing}
                  fontSize="12"
                  fontWeight="500"
                  key={order.id}
                >
                  {order?.signature || EMPTY_VALUE}
                </Typography>
              ))}
              {orders.filter(order => order).length - 1 > 0 && (
                <MoreDataCounter
                  counter={orders.filter(order => order).length - 1}
                  style={{ width: "max-content" }}
                />
              )}
            </div>
          );
        },
      }) as ColumnDef<TradingDocument>,
    paymentStatus: (size: number) =>
      columnHelper.accessor(row => row, {
        header: "płatność",
        size,
        cell: info => {
          const tradingDocument = info.getValue();
          return <PaymentStatus tradingDocument={tradingDocument} />;
        },
      }) as ColumnDef<TradingDocument>,
    paymentDeadlineDelta: (size: number) =>
      columnHelper.accessor(row => row, {
        header: "należne",
        size,
        cell: info => {
          const tradingDocument = info.getValue();
          if (
            tradingDocument.paymentDeadlineDelta === null ||
            tradingDocument.paymentStatus === "PAID"
          )
            return <EmptyValue fontSize="12" />;
          return (
            <Typography className={styles.invoiceSpacing} fontSize="12" fontWeight="700">
              <PaymentDeadlineDeltaLabel
                paymentDeadlineDelta={tradingDocument.paymentDeadlineDelta}
              />
            </Typography>
          );
        },
      }) as ColumnDef<TradingDocument>,
    notificationStatus: () =>
      columnHelper.text(
        row =>
          row.latestMessageStatus?.length > 0 &&
          tradingDocumentConstants.tradingDocumentNotificationStatusDict[row.latestMessageStatus],
        {
          header: "wysyłka",
          size: 70,
          typographyProps: {
            className: styles.invoiceSpacing,
          },
        },
      ) as ColumnDef<TradingDocument>,
    isDelivered: () =>
      columnHelper.accessor(row => row.isDelivered, {
        header: "status dostarczenia",
        size: 110,
        cell: info => {
          const isDelivered = info.getValue();
          if (isDelivered)
            return <Tag label="dostarczono" startIcon={MdiCheck} variant="success" />;
          return <Tag label="Nie dostarczono" type="outlined" variant="quaternary" />;
        },
      }) as ColumnDef<TradingDocument>,
    deliveredAt: () =>
      columnHelper.date(row => row.deliveredAt, {
        header: "data dostarczenia",
        size: 100,
      }) as ColumnDef<TradingDocument>,
  };
};

export const PaymentStatus = ({ tradingDocument }: { tradingDocument: TradingDocument }) => {
  switch (tradingDocument.paymentStatus) {
    case "PAID":
      return <Tag label="Opłacona" type="outlined" variant="success" />;
    case "NOT_PAID":
      return (
        <Tag
          label="Nieopłacona"
          type="outlined"
          variant={
            tradingDocument.paymentDeadline && dateExceedsToday(tradingDocument.paymentDeadline)
              ? "danger"
              : "quaternary"
          }
        />
      );
    case "PARTIALLY_PAID":
      return (
        <Tag
          label="Częściowo opłacona"
          type="outlined"
          variant={
            tradingDocument.paymentDeadline && dateExceedsToday(tradingDocument.paymentDeadline)
              ? "danger"
              : "quaternary"
          }
        />
      );
    default: {
      const exhaustiveCheck = tradingDocument.paymentStatus;
      console.error(`Unhandled payment status: ${exhaustiveCheck}`);
      return <EmptyValue fontSize="14" />;
    }
  }
};
