import styled from "@emotion/styled";
import { orderPackageExchangeActions } from "api/order-packages-exchange/actions";
import { OrderPackageExchange, SourceOrder } from "api/order-packages-exchange/models";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Button } from "components/miloDesignSystem/atoms/button";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { MdiQrCodeDownload } from "components/miloDesignSystem/atoms/icons/MdiQrCodeDownload";
import { Paper } from "components/miloDesignSystem/atoms/paper";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useParams } from "react-router";
import { queryString } from "utilities";
import { useExchangeColumns } from "./useExchangeColumns";
import { Search } from "components/miloDesignSystem/molecules/search";
import { getOrdersQuery } from "api/orders/calls";
import { NormalizePackagesListItem, ProductListItem } from "../shared/ProductListItem";
import { useSourceColumns } from "./useSourceColumns";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { SourceOrderPackageStatus } from "api/order-packages-exchange/enums";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { normalizeProductExchangePackage } from "../shared/normalizeProductExchangePackage";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { labelsFactory } from "api/labels/calls";
import { useNavigate } from "hooks/useNavigate";
import { assertIsDefined } from "utilities/assertIsDefined";
import { MdiArrowBack } from "components/miloDesignSystem/atoms/icons/MdiArrowBack";

export const SwapPackageView = () => {
  const { id } = useParams<{ id: OrderPackageExchange["id"] }>();
  const { data } = orderPackageExchangeActions.useOrderPackageExchange(id);
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <PageHeader
        searchBar={false}
        title={
          <Typography fontSize="20" fontWeight="700">
            Wymiana paczek
          </Typography>
        }
        viewLabel="WMS_PACKAGE_EXCHANGE"
      />

      <Paper className="my-2 mx-3 d-flex overflow-hidden">
        <div className="p-3 w-50 d-flex flex-column flex-1 h-100 overflow-hidden">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div>
              <Typography fontSize="16" fontWeight="400" className="mb-2">
                Przypisz paczki do zamówienia:
              </Typography>
              <Typography fontSize="20" fontWeight="700" className="mb-2">
                {data?.signature}
              </Typography>
            </div>
            <div className="d-flex align-items-center gap-2">
              <FileDownloadHandler
                mode="preview"
                factoryFn={() => {
                  assertIsDefined(data);
                  return labelsFactory.labels(
                    queryString.stringify({ orders: [data?.baseOrderId], addExchangedCodes: true }),
                    "Wymiana-paczek-",
                  );
                }}
                type="pdf"
              >
                {({ download, isLoading }) => (
                  <Button
                    startIcon={MdiQrCodeDownload}
                    size="small"
                    variant="gray"
                    isLoading={isLoading}
                    onClick={download}
                    className="text-uppercase"
                  >
                    Podgląd etykiety
                  </Button>
                )}
              </FileDownloadHandler>

              <Button
                size="small"
                variant="deepPurple"
                className="text-uppercase"
                onClick={() => navigate(`/wms/package-exchange?panelId=${id}`)}
              >
                Gotowe
              </Button>
            </div>
          </div>

          <Typography fontSize="16" fontWeight="400">
            Potrzebne produkty:
          </Typography>

          <ProductExchanges />
        </div>
        <Border />

        <div className="w-50">
          <Orders />
        </div>
      </Paper>
    </PageWrapper>
  );
};

const Orders = () => {
  const { id } = useParams<{ id: OrderPackageExchange["id"] }>();
  const { data, isLoading, error } = orderPackageExchangeActions.useSourceOrders(id);
  const patchMutation = orderPackageExchangeActions.usePatchOrderExchange();

  if (isLoading) {
    return (
      <div className="mt-4 flex-1 p-3">
        <Spinner size={40} />
      </div>
    );
  }

  if (error)
    return (
      <div className="mt-4">
        <CommonError status={error._httpStatus_} />
      </div>
    );

  return (
    <div className="p-3 d-flex flex-column flex-1 h-100 overflow-hidden">
      <div className="mb-4">
        <Typography fontSize="16" fontWeight="400" className="mb-2">
          Wyszukaj paczki do przesunięcia
        </Typography>
        <Search
          fetcherFn={getOrdersQuery}
          isNullable={false}
          onChange={order => {
            patchMutation.mutate({
              id,
              toUpdate: {
                sourceOrdersIds: [...new Set([...(data || []).map(e => Number(e.id)), order.id])],
              },
            });
          }}
          textFieldProps={{ placeholder: "Szukaj" }}
          externalSelectedItem={null}
        />
      </div>

      <ExchangeOrders />
    </div>
  );
};

const ExchangeOrders = () => {
  const { id } = useParams<{ id: OrderPackageExchange["id"] }>();
  const { data } = orderPackageExchangeActions.useSourceOrders(id);
  const postLinkPackagesMutation = orderPackageExchangeActions.usePostLinkPackages();
  const columns = useSourceColumns();

  if (!data?.length)
    return (
      <EmptySection
        className="mt-3"
        icon={<></>}
        borderStyle="dashed"
        label="Wyszukaj zamówienia lub meble aby zobaczyć dostępne paczki pasujące do zamówienia"
      />
    );

  return (
    <div className="overflow-auto d-flex flex-column">
      {data.map(orderExchange => (
        <div className="mt-4" key={orderExchange.id}>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <Typography fontSize="18" fontWeight="600">
              {orderExchange.signature}
            </Typography>
            <ProductDeleteButton orderExchange={orderExchange} data={data} />
          </div>
          <Button
            size="small"
            isLoading={postLinkPackagesMutation.isLoading}
            className="text-uppercase mb-2"
            startIcon={MdiArrowBack}
            variant="gray"
            onClick={() => {
              postLinkPackagesMutation.mutate({
                sourceOrdersIds: [orderExchange.id],
                orderPackagesExchangeId: id,
              });
            }}
          >
            Przenieś wszystkie pasujące paczki
          </Button>

          <div>
            {orderExchange.products.map((productExchange, index) => (
              <ProductListItem
                key={orderExchange.id}
                columns={columns}
                index={index}
                productName={productExchange.name}
                packages={normalizeSourceExchangePackages(productExchange)}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const ProductDeleteButton = ({
  data,
  orderExchange,
}: {
  data: SourceOrder[];
  orderExchange: SourceOrder;
}) => {
  const { id } = useParams<{ id: OrderPackageExchange["id"] }>();
  const patchMutation = orderPackageExchangeActions.usePatchOrderExchange();
  if (
    orderExchange.products.some(product =>
      product.packages.some(
        _package =>
          (_package.isAvailable && _package.status === SourceOrderPackageStatus.LINKED) ||
          (_package.isAvailable && _package.status === SourceOrderPackageStatus.CONFIRMED) ||
          (_package.isAvailable && _package.status === SourceOrderPackageStatus.RELEASED),
      ),
    )
  )
    return null;

  return (
    <IconButton
      isLoading={patchMutation.isLoading}
      icon={MdiClose}
      variant="transparent"
      onClick={() => {
        patchMutation.mutate({
          id,
          toUpdate: {
            sourceOrdersIds: data
              .filter(order => order.id !== orderExchange.id)
              .map(e => Number(e.id)),
          },
        });
      }}
    />
  );
};

const normalizeSourceExchangePackages = (
  sourceOrderProduct: SourceOrder["products"][number],
): NormalizePackagesListItem[] => {
  return sourceOrderProduct.packages.map(_package => ({
    confirmedBy: null,
    packageWarehouseStatus: null,
    confirmedAt: null,
    linkedOrder: null,
    uniqueCode: _package.uniqueCode,
    id: _package.uniqueCode,
    linkedCode: null,
    location: _package.location,
    exchangeStatus: null,
    internalId: _package.internalId,
    packageName: _package.name,
    sourceStatus: _package.status,
    isAvailable: _package.isAvailable,
  }));
};

const ProductExchanges = () => {
  const { id } = useParams<{ id: OrderPackageExchange["id"] }>();
  const { data } = orderPackageExchangeActions.useProductExchanges(
    queryString.stringify({ orderPackagesExchangeId: id, pageSize: "999" }),
  );
  const columns = useExchangeColumns();

  return (
    <div className="d-flex flex-1 flex-column overflow-auto ">
      {data.map((productExchange, index) => (
        <div key={productExchange.id}>
          <ProductListItem
            columns={columns}
            index={index}
            productName={productExchange.product.name}
            packages={normalizeProductExchangePackage(productExchange)}
          />
        </div>
      ))}
    </div>
  );
};
const Border = styled.div`
  height: 100%;
  width: 1px;
  min-width: 1px;
  background-color: ${colorPalette.neutralBlack16};
`;
